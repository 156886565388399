import { download } from './common';

export const ReportsApi = {
  preparePaymentOperationsReport: async (dateFrom: string, dateTo: string) => {
    await download('/pages/analyzepayments/ExportPaymentOperationsToExcel', {
      dateFrom,
      dateTo,
    });
  },

  prepareGroupPaymentsReport: async (period: string) => {
    await download('/pages/analyzepayments/ExportGroupPaymentsToExcel', {
      period,
    });
  },

  prepareUsersInfoReport: async () => {
    await download('/pages/analyzepayments/ExportUsersInfoToExcel', {});
  },
};
